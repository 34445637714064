/* You can add global styles to this file, and also import other style files */
@import "styles/variables";

@import "@fd/core/styles";

@import "styles/angular-material/overwrites";

// Custom styles, should be in /styles folder
:root {
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-dark: #212529;
  --bs-white: #fff;
}

.alert-success {
  color: #0f5132;
  background-color: #d1e7dd;
  border-color: #badbcc;
}
.alert-success .alert-link {
  color: #0c4128;
}
.alert-info {
  color: #055160;
  background-color: #cff4fc;
  border-color: #b6effb;
}
.alert-info .alert-link {
  color: #04414d;
}
.alert-warning {
  color: #664d03;
  background-color: #fff3cd;
  border-color: #ffecb5;
}
.alert-warning .alert-link {
  color: #523e02;
}
.alert-danger {
  color: #842029;
  background-color: #f8d7da;
  border-color: #f5c2c7;
}
.alert-danger .alert-link {
  color: #6a1a21;
}

// TEMP STYLES. Brings in dark mode changes made in FD Core. Can be removed after FD Base update
fd-layout-fixed-footer .fixed-footer {
  background: var(--theme-color-surface) !important;
}

// This can be removed after a future FD Base update that's on Angular Material 17+
// The toggles should be using the theme accent color at that point.
.mat-mdc-slide-toggle {
  --mdc-switch-selected-handle-color: #333;
  --mdc-switch-selected-hover-handle-color: #666;
  --mdc-switch-selected-focus-handle-color: #666;
  --mdc-switch-selected-pressed-handle-color: #666;
  --mdc-switch-selected-track-color: rgb(115, 115, 115);
  --mdc-switch-selected-hover-track-color: rgb(115, 115, 115);
  --mdc-switch-selected-focus-track-color: rgb(115, 115, 115);
  --mdc-switch-selected-pressed-track-color: rgb(115, 115, 115);
  --mdc-switch-unselected-pressed-track-color: rgb(115, 115, 115);
  --mdc-switch-selected-pressed-state-layer-color: rgb(115, 115, 115);
  .theme-alternate & {
    --mdc-switch-selected-handle-color: #fff !important;
  }
}
